<template>
  <v-container ref="container" class="print-sheet" grid-list-xl text-center>
    <v-flex xs12 class="hide-on-print">
      <span class="display-3 font-weight-bold primary--text"
        >Picking Sheet</span
      >
    </v-flex>
    <v-flex xs12 class="hide-on-print">
      <span class="display-1 primary--text">Order {{ selectedOrder.id }}</span>
    </v-flex>
    <v-flex xs12 class="ma-5">
      <v-btn text class="mr-2 hide-on-print" @click="back()">Back</v-btn>
      <v-btn @click="print()" class="hide-on-print accent">Print</v-btn>
    </v-flex>
    <S2SCard
      title="Order Details"
      dense
      color="white"
      flat
      toolbar-color="#d5d9dd"
      :container-props="{ class: 'py-0' }"
      class="mb-5"
    >
      <v-container fluid grid-list-md py-1 v-if="selectedOrder.id">
        <v-layout wrap>
          <v-flex pr-5 sm4 md4 class="print-details text-xs-left">
            <v-text-field
              hide-details
              pb-0
              readonly
              :value="selectedOrder.id"
              label="Order ID"
              mt-0
            ></v-text-field>
            <v-text-field
              ref="grn"
              hide-details
              pb-0
              :value="selectedOrder.grn ? selectedOrder.grn : '-'"
              label="GRN"
              mt-0
              readonly
            ></v-text-field>
            <v-text-field
              hide-details
              readonly
              :value="
                Utils.formatText(
                  selectedOrder.dateCreated,
                  Utils.TextType.DATE_YYYY_MM_DD
                )
              "
              label="Date Created"
            ></v-text-field>
            <v-text-field
              hide-details
              v-if="selectedOrder.user"
              readonly
              :value="selectedOrder.user.username"
              label="Placed By"
            ></v-text-field>
            <v-text-field
              hide-details
              readonly
              :value="selectedOrder.paymentStatus"
              label="Payment Status"
            ></v-text-field>
            <v-text-field
              hide-details
              readonly
              :value="
                selectedOrder.status.name ? selectedOrder.status.name : '-'
              "
              label="Order Status"
            ></v-text-field>
          </v-flex>
          <v-flex pr-5 sm4 md4 class="print-details">
            <v-text-field
              hide-details
              readonly
              :value="selectedOrder.organisation.code"
              label="Customer Code"
            ></v-text-field>
            <v-text-field
              hide-details
              readonly
              :value="
                selectedOrder.customer != null
                  ? `${selectedOrder.customer.name} (${selectedOrder.customer.contactNumber})`
                  : selectedOrder.organisation.name
              "
              label="Customer Name"
            ></v-text-field>
            <v-text-field
              hide-details
              v-if="!selectedOrder.user"
              readonly
              value="N/A"
              label="Customer"
            ></v-text-field>
            <v-text-field
              hide-details
              v-if="selectedOrder.user"
              readonly
              :value="`${selectedOrder.user.profile.firstName} ${selectedOrder.user.profile.surname}`"
              label="Contact"
            ></v-text-field>
            <v-text-field
              hide-details
              v-if="selectedOrder.user"
              readonly
              :value="selectedOrder.user.profile.cellphoneNumber"
              label="Number"
            ></v-text-field>
            <v-text-field
              hide-details
              v-if="selectedOrder.user"
              readonly
              :value="selectedOrder.user.profile.email"
              label="Email"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 md4 mb-5 pb-3 class="print-details">
            <v-text-field
              hide-details
              readonly
              :value="selectedOrder.deliveryType"
              label="Fulfillment"
            ></v-text-field>

            <br />
            <v-input
              :label="
                selectedOrder.deliveryType === 'DELIVERY'
                  ? 'Delivery Address:'
                  : 'Pickup Address:'
              "
              readonly
              class="address"
              hide-details
            >
              <div class="delivery-contents" v-if="selectedOrder.address">
                <div>{{ selectedOrder.address.complex }}</div>
                <br />
                {{ selectedOrder.address.streetNumber }}
                {{ selectedOrder.address.streetName }}
                <br />
                {{ selectedOrder.address.province }}
                <br />
                {{ selectedOrder.address.city }}
              </div>
              <div class="ml-2" v-else>N/A</div>
            </v-input>
            <br />
            <v-text-field
              hide-details
              readonly
              :value="
                selectedOrder.deliveryCost == null ||
                selectedOrder.deliveryCost == 0
                  ? 'Free'
                  : selectedOrder.deliveryCost
              "
              label="Delivery Fee"
            ></v-text-field>
            <div v-if="selectedOrder.parcels && selectedOrder.status.id === 5">
              <v-text-field
                hide-details
                readonly
                :value="selectedOrder.parcels.count"
                label="Number of Parcels"
              ></v-text-field>
              <v-chip
                v-for="size in selectedOrder.parcels.boxes"
                :key="size.key"
                class="mr-1"
                color="secondary"
                text-color="white"
                x-small
                pill
              >
                {{ size.name }}: {{ size.count }}
              </v-chip>
            </div>
          </v-flex>
          <v-flex v-if="selectedOrder.note">
            <br />
            <v-text-field
              hide-details
              readonly
              :value="selectedOrder.note"
              label="Order Notes"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex v-if="selectedOrder.address.note">
            <br />
            <v-text-field
              hide-details
              readonly
              :value="selectedOrder.address.note"
              label="Delivery Notes"
              outlined
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </S2SCard>
    <v-card>
      <v-data-table
        :headers="itemsHeader"
        :items="selectedOrder.items"
        :items-per-page="-1"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.options="item">
          <div v-if="!item.item.options.length">-</div>
          <div v-else v-for="option in item.item.options" :key="option.id">
            {{ option.name ? option.name : "-" }}
          </div>
        </template>
        <template v-slot:item.sku="item">
          <div v-if="!item.item.options.length">
            <div v-if="!item.item.detail.sku">-</div>
            <div v-else>{{ item.item.detail.sku }}</div>
          </div>
          <div v-else v-for="sku in item.item.options" :key="sku.id">
            <div v-if="!sku.sku">
              <div v-if="!item.item.detail.sku">-</div>
              <div v-else>{{ item.item.detail.sku }}</div>
            </div>
            <div v-else>{{ sku.sku }}</div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-flex xs12 class="ma-5">
      <v-btn text class="mr-2 hide-on-print" @click="back()">Back</v-btn>
      <v-btn @click="print()" class="hide-on-print accent">Print</v-btn>
    </v-flex>
  </v-container>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";

  export default Vue.extend({
    name: "OrderPickingNotes",

    props: {
      orderId: {
        type: String,
        default: "0",
      },
    },

    computed: {
      selectedOrder: function () {
        return this.$store.state.orders.selectedOrder;
      },
      boxSizes: function () {
        return this.$store.state.orders.boxSizes;
      },
      getSelectedOrder: function () {
        return this.$store.getters["orders/getSelectedOrder"];
      },
      getAllowedBoxSizes: function () {
        return this.$store.getters["orders/getAllowedBoxSizes"];
      },
    },

    data: function () {
      return {
        Utils: Utils,
        allowBoxSizes: [],

        itemsHeader: [
          { text: "SKU", value: "sku" },
          { text: "Name", value: "detail.name" },
          { text: "QTY", value: "quantity" },
          { text: "Option", value: "options" },
          { text: "Description", value: "detail.shortDescription" },
        ],
      };
    },

    created: function () {
      this.$store.dispatch("orders/fetchBoxSizes");
      this.$store.dispatch("orders/fetchAllowedBoxSizes");
      this.$store.dispatch("orders/fetchOrder", this.$props.orderId);
    },

    mounted: function () {
      // done printing or cancelled
      window.onafterprint = (e) => {
        this.back();
      };
    },

    methods: {
      onAllowedBoxSizesLoaded(newVal, oldVal) {
        this.allowBoxSizes = newVal;
      },
      back() {
        this.$router.push({ name: "view-order" });
      },
      async print() {
        await this.$nextTick();
        //this.printing = true;

        let section = document.getElementById("print");

        // clear element if order was previously printed
        if (section) {
          const element = document.getElementById("print");
          if (element != null) {
            element.outerHTML = "";
          }
        }

        window.print();
      },
    },

    /****************** UNPROCESSED LINES FROM ORIGINAL COMPONENT: ******************

      @orderModule.Action fetchOrder!: (orderId: number) => void;
      @orderModule.Action fetchBoxSizes!: () => void;
      @orderModule.Action fetchAllowedBoxSizes!: () => void;
      @orderModule.State selectedOrder!: Order;
      @orderModule.State boxSizes!;
      @orderModule.Getter getSelectedOrder!;
      @orderModule.Getter getAllowedBoxSizes!: Array<BoxSizes>;

      Utils = Utils;
      allowBoxSizes: Array<BoxSizes> = [];

      itemsHeader = [
       { text: "SKU", value: "sku" },
       { text: "Name", value: "detail.name" },
       { text: "QTY", value: "quantity" },
       { text: "Option", value: "options" },
       { text: "Description", value: "detail.shortDescription" },
      ];

      created() {
       this.fetchBoxSizes();
       this.fetchAllowedBoxSizes();
       this.fetchOrder(this.$props.orderId);
      }

      mounted() {
       // done printing or cancelled
       window.onafterprint = (e) => {
        this.back();
       };
      }

      @Watch("getAllowedBoxSizes", { deep: true, immediate: true })

     ********************************************************************************/
  });
</script>

<style>
  /* // Remove the default line at bottom of text field */
  .v-text-field .v-input__control > .v-input__slot:after,
  .v-text-field .v-input__control > .v-input__slot:before {
    border-style: none !important;
  }

  .address {
    position: relative;
  }
  .address .v-label {
    position: absolute !important;
    top: 0;
    font-size: 13px;
  }

  @media screen {
    .delivery-content {
      position: absolute;
      margin-top: 40px;
    }

    .v-text-field__details {
      display: none;
    }

    .outline {
      padding: 0;
      text-align: center;
    }

    .delivery-address {
      font-size: 13px;
    }

    label {
      font-size: 9px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  @media print {
    .v-card {
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .v-card .layout {
      margin: 0 !important;
      padding: 0 15px !important;
    }

    .v-card .v-data-table.elevation-1 {
      box-shadow: none !important;
      margin: 15px;
      padding: 15px;
    }

    body * {
      visibility: hidden;
      padding: 0 !important;
    }

    main {
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      height: 100%;
    }

    header,
    footer {
      display: none !important;
    }

    .print-sheet * {
      visibility: visible;
    }

    .hide-on-print {
      display: none !important;
    }

    .primary--text {
      font-weight: bold !important;
      text-transform: uppercase !important;
    }

    .delivery-address {
      font-size: 13px;
    }

    .v-input {
      min-height: 40px;
    }

    label {
      font-size: 9px;
      font-weight: bold;
      white-space: nowrap;
    }
  }
</style>
